.App {
  text-align: center;
}

table {
  margin-top: 1%;
}

.noteCollection{
  max-width: 25em;
  word-wrap: break-word;
}

.web-body{
  margin: 0.5%;
}

.nav-button-filter {
  background: none !important;
  border: none !important;
  padding: 1 !important;
  cursor: pointer;
}