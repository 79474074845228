.modify-973P-form{
    text-align: left;
    padding: 1%;
}

.align-btn{
    margin-left: 0%;
}

.warn-msg{
    color: rgb(248, 190, 1);
}

.text-area{
    display: flex;
}

.text-area > * {
    flex: 0 0 auto;
}


#copy-button {
    margin-top: 15%;
    margin-left: 1%;
    height: 2%;
}

#filter-button, #cancel-button {
    margin-left: 1px;
    margin-top: 14%;
}

.add-973P-form{
    text-align: left;
    padding: 1%;
}

.filter-section {
    width: 25%;
    margin: auto;
    padding-top: 1%;
}

.filter-select {
    padding-left: 10px;
}

.filter-form label{
    margin-top: 2%;
}

