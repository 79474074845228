.collections973 {
    margin: 2%;
}

.collections973, h2 {
    text-align: left;
}

.table-details{
    text-align: left;
    width: 50%;
}